body {
  margin: 0;
  font-family: 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
}

#root {
  display: flex;
  background-color: #f5f5f5;
  flex-grow: 1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Set the size of the div element that contains the map */
#map {
  height: 150px; /* The height is 400 pixels */
  width: 100%; /* The width is the width of the web page */
}

#streetView {
  height: 300px; /* The height is 400 pixels */
  width: 100%; /* The width is the width of the web page */
}
