div.fc-dayGridMonth-view {
  background-color: white;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: #ffcaca !important;
}

td.edl-create:hover {
  background-color: #caf3ff;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

td.edl-no-create {
  background-color: rgba(255, 215, 215, 0.276) !important;
  cursor: not-allowed;
}

td.on-event {
  background-color: initial !important;
  transition: background-color 0.7s ease;
}

a.fc-event {
  cursor: pointer !important;
}

.fc-daygrid-dot-event {
  display: flex;
  padding: 0px !important;
}

.fc-div-content {
  margin-left: 5px;
}

.fc-timegrid-event-harness-inset {
  font-size: 70%;
}

.lock-icon-container {
  font-size: 8px;
  color: white;
  position: absolute;
  top: 2px;
  right: 2px;
}

.fc-daygrid-more-link {
  color: rgb(255, 255, 255) !important;
  background-color: rgb(254, 73, 73) !important;
  font-weight: 500;
}
